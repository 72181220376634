import React, { Component } from 'react';
import { Spinner, Card, CardHeader, CardBody, Row, Col, Button } from 'reactstrap';
import { withRouter } from 'react-router';
import { Global } from '~/App'
import API from '~/API';
import moment from 'moment';
import _ from 'underscore';


class OrdenDeCompra extends Component {
  constructor() {
    super();
    this.state = {
      cargando: true,
      datos: {}
    }
  }
  print() {
    window.print();
  }
  componentWillMount() {
    API.Call(`Recaudacion/OrdenDePago/Detalle/${this.props.match.params.guid}`)
      .then(datos => this.setState({ datos, cargando: false }))
      .catch(e => alert(e)) // Hacer algo con el error
  }
  render() {
    return (
      <Card>
        <CardHeader className="titulo-modal">Resultado de Transacción</CardHeader>
        <CardBody className="cuerpo-modal">
          <Row>
            <Col sm={12}>

              {this.state.cargando ? <Spinner animation="border" role="status">
                Cargando...
              </Spinner> :
                <div>
                  {
                    !this.state.datos.montoPago ?
                      <div>
                        <Row>
                          <Col>
                            <h4>¡Gracias por realizar tu transacción con {this.state.datos.nombreEmpresa}!</h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p>Estos son los detalles que hemos ingresado:</p>
                          </Col>
                        </Row>
                      </div>
                      : this.state.datos.autorizacion ?
                        <div>
                          <Row>
                            <Col>
                              <h4>¡Gracias por pagar en {this.state.datos.nombreEmpresa}!</h4>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p>Estos son los detalles del pago que hemos ingresado:</p>
                            </Col>
                          </Row>
                        </div> :
                        this.state.datos.recaudador == "Khipu" ?
                          <div>
                            <Row>
                              <Col>
                                <h4>{this.state.datos.autorizacion ? <>¡Gracias por pagar en {this.state.datos.nombreEmpresa}!</> : <>Estamos verificando tu pago en {this.state.datos.nombreEmpresa}</>}</h4>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                {!this.state.datos.autorizacion && <p>Khipu puede tomar un tiempo en confirmar el pago. Te enviaremos un correo cuando esté todo listo.</p>}
                                <p>Estos son los detalles del pago que hemos ingresado:</p>
                              </Col>
                            </Row>
                          </div> :
                          <div>
                            <Row>
                              <Col>
                                <h4>No pudimos realizar tu pago en {this.state.datos.nombreEmpresa}!</h4>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <p>Estos son los detalles del pago que hemos intentado ingresar:</p>
                              </Col>
                            </Row>
                          </div>}
                  <Row>
                    <Col sm={12} md={{ size: 8, offset: 2 }} className="bordeCompleto">
                      <Row>
                        <Col sm={4} className="text-sm-right">
                          <strong>Codigo Transacción</strong>
                        </Col>
                        <Col sm={8}>
                          {this.props.match.params.guid}
                        </Col>
                      </Row>
                      {this.state.datos.montoPago ?
                        <Row>
                          <Col sm={4} className="text-sm-right">
                            <strong>Monto {this.state.datos.ordenesDeCompra ? "Total" : ""}</strong>
                          </Col>
                          <Col sm={8}>
                            $ {Global.FormatearNumero(this.state.datos.montoPago)} <small>CLP</small>
                          </Col>
                        </Row> : ""}
                      {this.state.datos.ordenesDeCompra?.map(e => <><Row>
                        <Col sm={4} className="text-sm-right">
                          <strong>Empresa</strong>
                        </Col>
                        <Col sm={8}>{e.Empresa}</Col>
                      </Row><Row>
                          <Col sm={4} className="text-sm-right">
                            <strong>Cuenta</strong>
                          </Col>
                          <Col sm={8}>{e.IdServicio}</Col>
                        </Row>
                        <Row>
                          <Col sm={4} className="text-sm-right">
                            <strong>Monto</strong>
                          </Col>
                          <Col sm={8}>
                            $ {Global.FormatearNumero(e.MontoPago)} <small>CLP</small>
                          </Col>
                        </Row></>)}
                      {this.state.datos.autorizacion ?
                        <Row>
                          <Col sm={4} className="text-sm-right">
                            <strong>Código {this.state.datos.recaudador}</strong>
                          </Col>
                          <Col sm={8} className="text-left">
                            {this.state.datos.autorizacion}
                          </Col>
                        </Row> : ""}
                      {this.state.datos.tipoPago ?
                        <Row>
                          <Col sm={4} className="text-sm-right">
                            <strong>Forma de Pago</strong>
                          </Col>
                          <Col sm={8} className="text-left">
                            {this.state.datos.tipoPago}
                          </Col>
                        </Row> : ""}
                      {this.state.datos.idServicio ?
                        <Row>
                          <Col sm={4} className="text-sm-right">
                            <strong>Cuenta</strong>
                          </Col>
                          <Col sm={8} className="text-left">
                            {this.state.datos.idServicio}
                          </Col>
                        </Row> : ""}
                      {this.state.datos.recaudador != "Generico" && <Row>
                        <Col sm={4} className="text-sm-right">
                          <strong>Recaudador</strong>
                        </Col>
                        <Col sm={8} className="text-left">
                          {this.state.datos.recaudador}
                        </Col>
                      </Row>}
                      <Row>
                        <Col sm={4} className="text-sm-right">
                          <strong>Fecha</strong>
                        </Col>
                        <Col sm={8} className="text-left">
                          {moment(this.state.datos.fechaPago).format("L LT")}
                        </Col>
                      </Row>
                      {this.state.datos.convenio ?
                        <div>
                          <br />
                          <hr />
                          <br />
                          <Row>
                            <Col sm={12} className="text-center">
                              <h4>Detalle cuotas convenio</h4>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={4} className="text-right">
                              <strong>Pie</strong>
                            </Col>
                            <Col sm={8} className="text-left">
                              <span>$ {Global.FormatearNumero(this.state.datos.convenio.MontoPie)}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={4} className="text-right">
                              <strong>Monto Convenido</strong>
                            </Col>
                            <Col sm={8} className="text-left">
                              <span>$ {Global.FormatearNumero(this.state.datos.convenio.MontoConvenido)}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={4} className="text-right">
                              <strong>Deuda</strong>
                            </Col>
                            <Col sm={8} className="text-left">
                              <span>$ {Global.FormatearNumero(this.state.datos.convenio.MontoConvenido * 1 + this.state.datos.convenio.MontoPie * 1)}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={4} className="text-right">
                              <strong>Ciclos de Gracia</strong>
                            </Col>
                            <Col sm={8} className="text-left">
                              <span>{this.state.datos.convenio.NoCiclosGracia}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={4} className="text-right">
                              <strong>Solicitante</strong>
                            </Col>
                            <Col sm={8} className="text-left">
                              <span>{this.state.datos.convenio.Nombre} ({this.state.datos.convenio.Rut})</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={4} className="text-right">
                              <strong>Direccion</strong>
                            </Col>
                            <Col sm={8} className="text-left">
                              <span>{this.state.datos.convenio.Direccion}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={4} className="text-right">
                              <strong>E-Mail</strong>
                            </Col>
                            <Col sm={8} className="text-left">
                              <span>{this.state.datos.convenio.Mail}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={4} className="text-right">
                              <strong>Teléfono</strong>
                            </Col>
                            <Col sm={8} className="text-left">
                              <span>{this.state.datos.convenio.Telefono}</span>
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col sm={2} className="text-sm-right">

                            </Col>
                            <Col sm={2} className="text-left">
                              <strong>Cuota</strong>
                            </Col>
                            <Col sm={2} className="text-left">
                              <strong>Interés</strong>
                            </Col>
                            <Col sm={2} className="text-left">
                              <strong>IVA Interés</strong>
                            </Col>
                            <Col sm={2} className="text-left">
                              <strong>Cuota Total</strong>
                            </Col>
                            <Col sm={2} className="text-left">
                              <strong>Saldo</strong>
                            </Col>
                          </Row>
                          {_.map(this.state.datos.convenio.DetalleCuotas, c =>
                            <Row>
                              <Col sm={2} className="text-sm-right">
                                <strong>Cuota {c.no_cuota}</strong>
                              </Col>
                              <Col sm={2} className="text-left">
                                $ {Global.FormatearNumero(c.monto_cuota)}
                              </Col>
                              <Col sm={2} className="text-left">
                                $ {Global.FormatearNumero(c.interes_conv)}
                              </Col>
                              <Col sm={2} className="text-left">
                                $ {Global.FormatearNumero(c.iva_interes)}
                              </Col>
                              <Col sm={2} className="text-left">
                                $ {Global.FormatearNumero(c.monto_total_cuota)}
                              </Col>
                              <Col sm={2} className="text-left">
                                $ {Global.FormatearNumero(c.saldo_conv)}
                              </Col>
                            </Row>
                          )}
                        </div>
                        : ""}
                      <Row>
                        <Col sm={12} className="text-center">
                          <br />
                          <Button color="primary" onClick={this.print}>Imprimir</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              }

            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }
}


export default withRouter(OrdenDeCompra);